import { atom, selector } from 'recoil'

export const langModelState = atom({
  key: 'langModelState',
  default: null,
})

export const langModelListState = selector({
  key: 'langModelListState',
  get: ({ get }) => {
    const data = get(langModelState);
    return data ? data.list : null;
  },
});
