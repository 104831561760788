// import { useState, useEffect } from 'react';
// import { CSSTransition } from 'react-transition-group';
//
// const TopButton = () => {
//     const [isVisible, setIsVisible] = useState(false);
//
//     useEffect(() => {
//         const handleScroll = () => {
//             setIsVisible(window.scrollY !== 0);
//         };
//
//         window.addEventListener('scroll', handleScroll);
//
//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);
//
//     const handleClick = () => {
//         window.scrollTo({ top: 0, behavior: 'smooth' });
//     };
//
//     return (
//         <CSSTransition
//             in={isVisible}
//             timeout={300} // 애니메이션 지속 시간(ms)
//             classNames="fade"
//             unmountOnExit
//         >
//             <button className="btn_top" aria-label="TOP" onClick={handleClick}>
//                 <i className="ico_btn" />
//             </button>
//         </CSSTransition>
//     );
// };
//
// export default TopButton;

import { useEffect } from 'react';

const TopButton = () => {
    useEffect(() => {
        const btnTop = document.querySelector('.btn_top');

        const handleScroll = () => {
            if (window.scrollY !== 0) {
                btnTop.style.display = 'block';
            } else {
                btnTop.style.display = 'none';
            }
        };

        btnTop.addEventListener('click', () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <button className="btn_top" aria-label="TOP" style={{ display: 'none' }}>
            <i className="ico_btn" />
        </button>
    );
};

export default TopButton;
