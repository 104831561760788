// components/DivGroup.jsx

import React from 'react';

const OptionGroup = ({optionValueName, onOptionChange}) => {

    const handleInputChange = (event) => {
        onOptionChange(optionValueName, event.target.value);
    };

    return (
        <>
            <div className="row">
                <label htmlFor="inputTemperature" className="col_sm8 col_md6 col_lg5">temperature (0.0~1.0)</label>
                <input type="text" id="inputTemperature" name="inputTemperature" value={optionValues.temperature} onChange={handleInputChange} />
            </div>
            <div className="row">
                <label htmlFor="inputMaxOutputToken" className="col_sm8 col_md6 col_lg5">maxOutputToken (1~1024)</label>
                <input type="text" id="inputMaxOutputToken" name="inputMaxOutputToken" value={optionValues.maxOutputToken} onChange={handleInputChange} />
            </div>
            <div className="row">
                <label htmlFor="inputTopK" className="col_sm8 col_md6 col_lg5">topK (1~40)</label>
                <input type="text" id="inputTopK" name="inputTopK" value={optionValues.topK} onChange={handleInputChange} />
            </div>
            <div className="row">
                <label htmlFor="inputTopP" className="col_sm8 col_md6 col_lg5">topP (0.0~1.0)</label>
                <input type="text" id="inputTopP" name="inputTopP" value={optionValues.topP} onChange={handleInputChange} />
            </div>
        </>
    );
};

export default OptionGroup;
