import { atom } from 'recoil'

export const isShowMenu = atom({
  key: 'isShowMenu',
  default: true,
})

export const contentState = atom({
  key: 'contentState',
  default: '', // 'Republican presidential candidates trudged through blistering subzero temperatures in Iowa on Sunday to deliver closing arguments ahead of Monday’s long-awaited caucuses — the first nominating contest in an election year shaping up as a test of starkly competing political visions and American democracy.\n' +
  // '\n' +
  // 'Cut through the 2024 election noise. Get The Campaign Moment newsletter.\n' +
  // '\n' +
  // '\n' +
  // 'Donald Trump, the clear Republican polling leader, offered a grievance-laden and defiant closing pitch in suburban Des Moines in which he lashed out at his main rivals as disloyal, derided the state’s Republican governor and made slashing attacks on his critics. Speaking at one of his few in-person events in the final stretch, he warned his supporters against complacency on what is forecast to be a bitterly cold caucus night.',
})
