// 예시: components/CustomHead.js

import Head from 'next/head';

function CustomHead({ title, description }) {
    return (
        <Head>
                <meta charSet="utf-8"/>
                <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                <meta name="format-detection" content="telephone=no, address=no, email=no"/>
                <title>중앙일보 영문 기사 요약 . 번역 시스템</title>
                <meta name="title" content="중앙일보 영문 기사 요약 . 번역 시스템"/>
                <meta name="description" content=""/>
                <meta name="news_keywords" content="중앙일보, 중앙일보플러스"/>
                <meta property="og:url" content="https://www.joongang.co.kr"/>
                <meta property="og:title" content="중앙일보 영문 기사 요약 . 번역 시스템"/>
                <meta property="og:type" content="website"/>
                <meta property="og:description" content=""/>
                <meta property="og:image" content=""/>
                <meta property="og:image:kakao" content=""/>
                <meta property="og:site_name" content="중앙일보"/>
                <meta property="og:locale" content="ko_KR"/>
                <meta property="article:author" content="https://www.joongang.co.kr"/>
                <meta property="article:publisher" content="https://www.joongang.co.kr"/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content="중앙일보 영문 기사 요약 . 번역 시스템"/>
                <meta name="twitter:description" content=""/>
                <meta name="twitter:image:src" content=""/>
                <link rel="apple-touch-icon" sizes="180x180" href="https://img.joongang.co.kr/pubimg/favicon/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="https://img.joongang.co.kr/pubimg/favicon/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="https://img.joongang.co.kr/pubimg/favicon/favicon-16x16.png" />
                <link rel="manifest" href="https://img.joongang.co.kr/pubimg/favicon/site.webmanifest" />
                <link href="https://img.joongang.co.kr/pubimg/favicon/favicon.ico" rel="shortcut icon" />
                {/*<link rel="stylesheet" href="https://dev-static.joongang.co.kr/css/etc/translate.css" type="text/css" />*/}
            </Head>
    );
}

export default CustomHead;
