// 예시: components/Toast.js

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = ({ message, autoClose }) => {
    toast.info(message, { autoClose });
    return null;
};

export default Toast;
