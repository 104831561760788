export const OSLO_API_DOMAIN = 'https://jmnet-oslo-api-75zws3ssea-du.a.run.app';

export const API_URLS = {
    MODEL_VERTEXAI_PARM2_ENGLISH: '/api/vertexai/palm2-eng',
    MODEL_VERTEXAI_PARM3_MODEL_LIST: '/api/vertexai/palm3-model-list',
    MODEL_VERTEXAI_PARM3_COMMON: '/api/vertexai/palm3'
}

export const EXTERNAL_API_URLS = {
    VERTEXAI_MODEL_LIST: `${OSLO_API_DOMAIN}/VertexAI/0.3/MethodList`,

}